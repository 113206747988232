import { Suspense, lazy } from 'react';
import LoadingScreen from './components/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const TeacherHomePage = Loadable(lazy(() => import('./pages/TeacherHomePage')));
const LandingPage = Loadable(lazy(() => import('./pages/LandingPage')));
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage')));
const RegisterPage = Loadable(lazy(() => import('./pages/RegisterPage')));

const CourseUpdatePage = Loadable(lazy(() => import('./pages/CourseUpdatePage')));
const SectionUpdatePage = Loadable(lazy(() => import('./pages/SectionUpdatePage')));
const StudySetUpdatePage = Loadable(lazy(() => import('./pages/StudySetUpdatePage')));
const StudySetUpdateAltPage = Loadable(lazy(() => import('./pages/StudySetUpdateAltPage')));
const QuizUpdatePage = Loadable(lazy(() => import('./pages/QuizUpdatePage')));
const QuizDetailsPage = Loadable(lazy(() => import('./pages/QuizDetailsPage')));
const StudySetMatchingGamePage = Loadable(lazy(() => import('./pages/StudySetMatchingGamePage')));

const QuizPage = Loadable(lazy(() => import('./pages/QuizPage')));
const QuizzesPage = Loadable(lazy(() => import('./pages/QuizzesPage')));
const CoursePage = Loadable(lazy(() => import('./pages/CoursePage')));
const CourseStudentPage = Loadable(lazy(() => import('./pages/CourseStudentPage')));
const CoursesPage = Loadable(lazy(() => import('./pages/CoursesPage')));
const StudySetsPage = Loadable(lazy(() => import('./pages/StudySetsPage')));
const StudySetStudentPage = Loadable(lazy(() => import('./pages/StudySetStudentPage')));
const StudySetTeacherPage = Loadable(lazy(() => import('./pages/StudySetTeacherPage')));
const StudySetQuizPage = Loadable(lazy(() => import('./pages/StudySetQuizPage')));
const SectionPage = Loadable(lazy(() => import('./pages/SectionPage')));
const FeedbackPanelPage = Loadable(lazy(() => import('./pages/FeedbackPanelPage')));
const LogNotesPage = Loadable(lazy(() => import('./pages/LogNotesPage')));
const SupportPage = Loadable(lazy(() => import('./pages/SupportPage')));

// Student Pages
const StudentHomePage = Loadable(lazy(() => import('./pages/StudentHomePage')));
const StudentAddTeacherPage = Loadable(lazy(() => import('./pages/StudentAddTeacherPage')));
const StudentAddCoursePage = Loadable(lazy(() => import('./pages/StudentAddCoursePage')));

const TeacherPage = Loadable(lazy(() => import('./pages/TeacherPage')));
const SelectSchoolPage = Loadable(lazy(() => import('./pages/SelectSchoolPage')));


export const guestRoutes = [
  { path: '/', element: <LandingPage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'support', element: <SupportPage /> },
  { path: 'quiz/:quizId', element: <QuizPage />},
  { path: 'quiz/:quizId/active/:activeQuizId', element: <QuizPage />},
  { path: 'teachers/:teacherId', element: <TeacherPage />},
  { path: 'course/:courseId', element: <CourseStudentPage />},
  { path: 'studyset/:studysetId', element: <StudySetStudentPage />},
  { path: 'studyset-quiz/:studysetId', element: <StudySetQuizPage />},
  { path: 'studyset-match/:studysetId', element: <StudySetMatchingGamePage />},
  {
    path: '/*', element: <LandingPage />
  }
];

export const studentRoutes = [
  { path: '/', element: <StudentHomePage /> },
  { path: '/home', element: <StudentHomePage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'support', element: <SupportPage /> },
  { path: 'quiz/:quizId', element: <QuizPage />},
  { path: 'quiz/:quizId/active/:activeQuizId', element: <QuizPage />},
  { path: 'teachers/:teacherId', element: <TeacherPage />},
  { path: 'course/:courseId', element: <CourseStudentPage />},
  { path: 'studyset/:studysetId', element: <StudySetStudentPage />},
  { path: 'studyset-quiz/:studysetId', element: <StudySetQuizPage />},
  { path: 'studyset-match/:studysetId', element: <StudySetMatchingGamePage />},
  { path: 'add-teacher', element: <StudentAddTeacherPage />},
  { path: 'add-course', element: <StudentAddCoursePage />},
  { path: 'drag', element: <StudySetMatchingGamePage />},
  {
    path: '/*', element: <LandingPage />
  }
];

export const teacherRoutes = [
  { path: '/', element: <TeacherHomePage /> },
  { path: '/home', element: <TeacherHomePage /> },
  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'support', element: <SupportPage /> },
  { path: 'create-course', element: <CourseUpdatePage /> },
  { path: 'update-course/:courseId', element: <CourseUpdatePage /> },
  { path: 'create-section', element: <SectionUpdatePage /> },
  { path: 'update-section/:sectionId', element: <SectionUpdatePage /> },
  // { path: 'create-studyset', element: <StudySetUpdatePage /> },
  // { path: 'update-studyset/:studysetId', element: <StudySetUpdatePage /> },
  { path: 'create-studyset', element: <StudySetUpdateAltPage /> },
  { path: 'update-studyset/:studysetId', element: <StudySetUpdateAltPage /> },
  { path: 'studyset/:studysetId', element: <StudySetTeacherPage />},
  { path: 'studyset-match/:studysetId', element: <StudySetMatchingGamePage />},
  { path: 'create-quiz', element: <QuizUpdatePage /> },
  { path: 'update-quiz/:quizId', element: <QuizUpdatePage /> },
  { path: 'quiz-details/:quizId', element: <QuizDetailsPage /> },
  { path: 'quiz/:quizId', element: <QuizPage />},
  { path: 'quiz/:quizId/active/:activeQuizId', element: <QuizPage />},
  { path: 'course/:courseId', element: <CoursePage />},
  { path: 'section/:sectionId', element: <SectionPage />},
  { path: 'select-school', element: <SelectSchoolPage />},
  { path: 'courses', element: <CoursesPage />},
  { path: 'studysets', element: <StudySetsPage />},
  { path: 'quizzes', element: <QuizzesPage />},
  { path: 'feedback', element: <FeedbackPanelPage />},
  { path: 'drag', element: <StudySetMatchingGamePage />},
  { path: 'notes', element: <LogNotesPage />},
  {
    path: '/*',
    element: <TeacherHomePage />
  }
];