import { useEffect, useState } from 'react';
import { useRoutes } from 'react-router';
// import { responsiveFontSizes } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { guestRoutes, studentRoutes, teacherRoutes } from './routes'
import { themeLight } from './theme';
import { useAuth } from './context/authContext';
import AlertsTemplate from './components/alerts/AlertsTemplate';
import LogNotes from './components/feedback/LogNotes';
import Loading from './components/ui/Loading';
// import Feedback from './components/ui/Feedback';

// const responsiveTheme = responsiveFontSizes(theme);

function App() {
  const authContext = useAuth();
  const teacherContent = useRoutes(teacherRoutes);
  const studentContent = useRoutes(studentRoutes);
  const guestContent = useRoutes(guestRoutes);

  console.log('authContext account', authContext.account);
  // console.log('authContext account type', authContext.account.type);

  return (
    <>
      <AlertsTemplate />

      <ThemeProvider theme={themeLight}>
        { authContext.account &&
          (authContext.account.type === 'teacher') // Must be a teacher account
            ? <ContentGate
                message="teacherContent"
                content={teacherContent}
                pendingContent={guestContent}
                // status={role}
              />
            : null
        }
        { authContext.account &&
          (authContext.account.type === 'student') // Must be a student account
            ? <ContentGate
                message="studentContent"
                content={studentContent}
                pendingContent={guestContent}
                // status={role}
              />
            : null
        }
        { !authContext.account || !authContext.account.type // Must be a student account
            ? <ContentGate
                message="guestContent"
                content={guestContent}
                pendingContent={guestContent}
                // status={role}
              />
            : null
        }

      </ThemeProvider>


    </>
  );
}

export default App;

const ContentGate = (props) => {
  const { message, content, pendingContent } = props;
  const status = 'Active'

  console.log(message);

  return (
    status === 'Active'
      ? 
        content
      : 
        pendingContent
  )
}
