import React from 'react'
import { useTheme } from '@emotion/react';
import { Grid, LinearProgress } from '@mui/material';

const Loading = (props) => {
  const {
    bars,
    boxHeight,
    bottom,
    top,
    color1,
    color2,
    totalWidth,
    barHeight,
    barWidth,
    barWidth1,
    barWidth2,
    barWidth3,
    barWidth4,
    barWidth5,
    barWidth6
  } = props;
  const theme = useTheme();

  const width = barWidth ? barWidth : '100%';
  const height = barHeight ? barHeight : 40;

  const barsArr = [
    {id: 1, height: height, width: barWidth1 ? barWidth1 : width},
    {id: 2, height: height, width: barWidth2 ? barWidth2 : width},
    {id: 3, height: height, width: barWidth3 ? barWidth3 : width},
    {id: 4, height: height, width: barWidth4 ? barWidth4 : width},
    {id: 5, height: height, width: barWidth5 ? barWidth5 : width},
    {id: 6, height: height, width: barWidth6 ? barWidth6 : width},
  ]

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height={boxHeight ? boxHeight : "70vh"}
    >
      <Grid
        container
        direction="column"
        width={totalWidth ? totalWidth : '100%'}
      >
        {top}
        {barsArr.slice(0, bars ? bars : 6).map(bar => (
            <LinearProgress
              key={bar.id}
              sx={{
                background:color1 ? color1 : `${theme.palette.primary.main}20`,
                "& .MuiLinearProgress-bar": {
                  background:color2 ? color2 : `${theme.palette.primary.main}40`,
                },
                height:bar.height,
                width:bar.width,
                borderRadius:10,
                my:1
              }}
            />
          ))
        }
        {bottom}
        {/* <Typography variant="h2" sx={{ml:2}}>Loading</Typography> */}
      </Grid>
    </Grid>
  )
}

export default Loading