import React, { useState, useEffect, useRef} from 'react'
import { Box, Collapse, Grid, IconButton, Stack, Typography } from '@mui/material'
import { Close } from '@mui/icons-material';
import { useAuth } from '../../context/authContext';


  const AlertsTemplate = (props) => {
    const { text, authCont} = props;
    const authContext = useAuth();
    const [localGoTime, setLocalGoTime] = useState(false);
    const [content, setContent] = useState(null);
    const color = "#9B30F9"

    if (authCont) {console.log('FROM AUTH CONTEXT')} else {console.log('FROM APP')}
  
    //Alerts
    useEffect(() => {
      if (authContext?.alertRef?.current) {
        setContent(authContext.alertRef.current)
        setLocalGoTime(true);
        setTimeout(() => {
          setLocalGoTime(false)
          authContext.alertRef.current = null;
        }, 2500);
      }
    }, [authContext?.fireAlert])

    const handleStop = () => {
      setLocalGoTime(false);
    };

    return (
      <Box
      className="AlertsTemplate one"
      sx={{
        position:'fixed',
        // width:goTime ? '100%' : '0%',
        // justifyContent:'center',
        top:20,
        // height:'100vh',
        // left:'50%',
        right:0, //'5%',
        zIndex: 3000
      }}
      xs={12}
    >
      <Collapse
        orientation="horizontal"
        in={localGoTime}
      >
        <Grid
          item
          className="AlertsTemplate two"
          container
          sx={{
            justifyContent:'center',
            mr:15
          }}
          xs={12}
        >
          <Grid
            className="AlertsTemplate three"
            item
          >
            <Box
              className="AlertsTemplate four"
              component={Stack}
              direction="row"
              alignItems="center"
              sx={{
                // backgroundColor:color,
                background:color,
                borderRadius:2,
                py:1,
                px:3
              }}
            >
              <Typography sx={{textAlign:'center', fontWeight:400, color:text ? text : '#fff'}} noWrap>
                {content}
              </Typography>
              <IconButton
                onClick={handleStop}
                sx={{
                  ml:1
                }}
              >
                <Close sx={{color:text ? text : '#fff'}} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  )
}

export default AlertsTemplate